import React, { useState } from 'react'
import { BlogProps } from './blog-card'
import BlogCard from './blog-card'
import * as UI from '@/ui'

type PaginationProps = {
  articles: BlogProps[]
  articlesPerPage?: number
  showBadge?: boolean
  showPagination?: boolean
}

const AllArticles = ({ articles, articlesPerPage = 24, showBadge = true, showPagination = true }: PaginationProps) => {
  const [currentPage, setCurrentPage] = useState(1)

  const indexOfLastArticle = currentPage * articlesPerPage
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage
  const currentArticles = articles.slice(indexOfFirstArticle, indexOfLastArticle)

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }

  return (
    <UI.Block gap="medium" id="LearnHubAllArticles">
      <div className="flex flex-wrap gap-10 justify-center">
        {currentArticles.map((article) => (
          <BlogCard blog={article} key={article.title} showBadge={showBadge} />
        ))}
      </div>

      {showPagination && (
        <UI.Pagination
          articlesPerPage={articlesPerPage}
          totalArticles={articles.length}
          currentPage={currentPage}
          parentId="LearnHubAllArticles"
          paginate={paginate}
        />
      )}
    </UI.Block>
  )
}

export default AllArticles
