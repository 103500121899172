import { hoverStyles } from '../../pages/learn/index.page'
import blurImageJson from '../../data/markdown-blur-images.json'
import { getMainCategory } from '../../helpers/blogs'
import useUi from '../ui/use-ui'
import { fromIsoString } from '../../helpers/date'
import * as UI from '@/ui'

export type BlogProps = {
  title: string
  summary: string
  slug: string
  author: string
  reviewer?: string
  published: string
  updated?: string | null
  readingTime: number
  categories: string[]
  hero: string
  relevantArticles?: string[]
  relatedProducts?: string[]
}
export type BlogCardColours = 'lightGrey' | 'darkGrey' | 'white'

export type BlogCardProps = {
  blog: BlogProps
  showBadge?: boolean
  colour?: BlogCardColours
}

export const BlogCard = ({ blog, showBadge, colour = 'lightGrey' }: BlogCardProps) => {
  const blogDate = fromIsoString(blog.updated || blog.published)
  const mainCategory = getMainCategory(blog.categories[0])

  const colours = {
    lightGrey: 'bg-selphGrey-50',
    darkGrey: 'bg-selphGrey-100',
    white: 'bg-white',
  }

  const ui = useUi({
    styles: {
      color: { options: colours, selected: colour },
    },
    name: 'Blog Card',
  })

  return (
    <UI.Link type="basic" to={['blogPost', { category: mainCategory, slug: blog.slug }]}>
      <div
        className={`${ui.className} flex flex-col w-[280px] min-[340px]:w-xs h-full min-h-[404px] items-stretch rounded-3xl  ${hoverStyles}`}
      >
        <div className="relative mb-3 flex items-center justify-center h-[256px]">
          <UI.Image
            className="object-cover object-center"
            blurImageJson={blurImageJson}
            blurImageJsonFolder="/images/blogs/"
            fill
            sizes="(nin-width: 340px) 320px, 280px"
            src={`/images${blog.hero}`}
            alt={blog.title}
          />

          <div className="absolute bg-selphBlue-500 inset-0 opacity-40" />

          {showBadge && (
            <UI.Badge size="medium" color="secondary" className="absolute top-5 right-5 capitalize">
              {blog.categories[0]}
            </UI.Badge>
          )}
        </div>

        <div className="grow flex flex-col">
          <UI.Heading size="xxs" weight="regular" color="midBlue" className="text-left mb-5 px-4 grow">
            {blog.title}
          </UI.Heading>

          <div className="flex justify-between px-4 mb-3 text-sm text-selphBlue-500">
            <time dateTime={blogDate.toISO() || ''}>{blogDate.toFormat('dd MMMM, yyyy')}</time>
            <p>{`${blog.readingTime}-min read`}</p>
          </div>
        </div>
      </div>
    </UI.Link>
  )
}

export default BlogCard
