import { getMainCategory } from '../../helpers/blogs'
import { fromIsoString } from '../../helpers/date'
import { BlogProps } from './blog-card'
import * as UI from '@/ui'

export const BlogWhatsNew = ({ blog }: { blog: BlogProps }) => {
  const articleDate = fromIsoString(blog.updated || blog.published)
  const mainCategory = getMainCategory(blog.categories[0])

  return (
    <div className="flex flex-col md:flex-row gap-5">
      <div className="relative w-full min-w-[250px] max-w-[400px] h-[300px] lg:h-auto mx-auto rounded-3xl shadow-[1.5px_1.5px_0px_1.5px_#E22E89]">
        <UI.Link color="none" to={['blogPost', { category: mainCategory, slug: blog.slug }]}>
          <UI.Image
            src={`/images${blog.hero}`}
            alt={blog.title}
            fill
            className="object-center object-cover rounded-3xl"
          />
          <UI.Badge size="medium" color="secondary" className="absolute top-5 right-5 capitalize">
            {blog.categories[0]}
          </UI.Badge>
        </UI.Link>
      </div>

      <div className="flex flex-col justify-between mx-auto">
        <UI.Block>
          <UI.Heading size="xs" weight="regular" color="midBlue" className="text-left">
            {blog.title}
          </UI.Heading>

          <UI.Block gap="none" className="">
            <time dateTime={articleDate.toISO() || ''} className="text-sm text-selphBlue-500">
              {articleDate.toFormat('dd MMMM, yyyy')}
            </time>
            <UI.Paragraph size="small" color="midBlue">
              {blog.readingTime}-min read
            </UI.Paragraph>
          </UI.Block>

          <UI.Paragraph size="medium" color="midBlue" className="flex-grow text-left">
            {blog.summary}
          </UI.Paragraph>
        </UI.Block>

        <div className="mt-8 md:mt-4 mx-auto lg:mx-0">
          <UI.Link
            type="button"
            theme="solid"
            color="pink"
            size={{ default: 'medium', md: 'large' }}
            textSize="text-lg"
            to={['blogPost', { category: mainCategory, slug: blog.slug }]}
          >
            Read Article
          </UI.Link>
        </div>
      </div>
    </div>
  )
}

export default BlogWhatsNew
