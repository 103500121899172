import { useUi, type Breakpoints } from './use-ui'

export type DividerSizes = 'medium' | 'mediumFull' | 'large'
export type DividerColours = 'light' | 'dark' | 'pink'

export const Divider = ({
  className,
  size = 'medium',
  color = 'light',
}: {
  color?: DividerColours
  size?: Breakpoints<DividerSizes>
  className?: string
}) => {
  const sizes = {
    default: '',
    medium: '',
    large: 'border-t-2 pb-3 mt-4',
  }

  const colors = {
    default: '',
    light: 'bg-gradient-to-r from-white to-transparent',
    dark: 'bg-gradient-to-r from-selphBlue-500 to-transparent',
    pink: 'bg-hotPink-500',
  }

  const ui = useUi({
    styles: {
      size: { options: sizes, selected: size },
      color: { options: colors, selected: color },
    },
    name: 'Divider',
    className: `${className}  h-1 rounded`,
  })

  return <hr className={ui.className} />
}

Divider.displayName = 'Divider'

export default Divider
