import { BlogProps } from './blog-card'
import BlogCard from './blog-card'

export type TrendingArticlesListProps = {
  articles: BlogProps[]
}

export const TrendingArticlesList = ({ articles }: TrendingArticlesListProps) => {
  return (
    <div className="flex flex-wrap gap-y-10 gap-x-5 mx-auto justify-around">
      {articles.map((article) => (
        <BlogCard blog={article} key={article.title} showBadge />
      ))}
    </div>
  )
}

export default TrendingArticlesList
