import React, { useEffect, useState } from 'react'

export type PaginationProps = {
  articlesPerPage: number
  totalArticles: number
  currentPage: number
  parentId?: string
  paginate: (number: number) => void
}

export const Pagination = ({ articlesPerPage, totalArticles, currentPage, parentId, paginate }: PaginationProps) => {
  const [hasClicked, setHasClicked] = useState(false)

  useEffect(() => {
    if (hasClicked && parentId) {
      scrollToTop(parentId)
    }
  }, [currentPage, parentId, hasClicked])

  const pageNumbers = []
  const totalPages = Math.ceil(totalArticles / articlesPerPage)

  let startPage = Math.max(1, currentPage - 2)
  let endPage = Math.min(startPage + 4, totalPages)

  if (currentPage <= 3) {
    endPage = Math.min(5, totalPages)
  }

  if (currentPage >= totalPages - 2) {
    startPage = Math.max(totalPages - 4, 1)
  }

  pageNumbers.push('< Prev')

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i)
  }

  pageNumbers.push('Next >')

  const disablePrev = (number: string) => {
    return number === '< Prev' && currentPage === 1
  }

  const disableNext = (number: string) => {
    return number === 'Next >' && currentPage === totalPages
  }

  const handleClick = (pageNumber: number) => {
    setHasClicked(true)
    paginate(pageNumber)
  }

  if (totalPages === 1) return null

  return (
    <nav>
      <ul className="flex justify-center text-lg text-selphBlue-500 ">
        {pageNumbers.map((number, index) => (
          <li key={index} className="mx-3 font-semibold">
            {typeof number === 'number' ? (
              <button
                onClick={() => {
                  handleClick(number)
                }}
                className={`hover:text-hotPink-500 ${
                  number === currentPage &&
                  'text-lg  border-2 border-hotPink-500 px-1.5 rounded-md hover:text-selphBlue-500'
                }`}
                disabled={number === currentPage}
                aria-label={`Articles page ${number}`}
                aria-disabled={number === currentPage}
              >
                {number}
              </button>
            ) : (
              <button
                onClick={() => {
                  handleClick(number === 'Next >' ? currentPage + 1 : currentPage - 1)
                }}
                className={`hover:text-hotPink-500 ${
                  (disablePrev(number) || disableNext(number)) && 'opacity-50 hover:text-selphBlue-500'
                }`}
                disabled={disablePrev(number) || disableNext(number)}
                aria-label={`${(number === 'Next >' && 'Next') || 'Previous'} page`}
                aria-disabled={disablePrev(number) || disableNext(number)}
              >
                {number}
              </button>
            )}
          </li>
        ))}
      </ul>
    </nav>
  )
}

Pagination.displayName = 'Pagination'

export default Pagination

const scrollToTop = (parentId: string) => {
  const parent = document.getElementById(parentId)
  parent && parent.scrollIntoView({ behavior: 'smooth' })
}
