import { hoverStyles } from '../../pages/learn/index.page'
import { CategoryProps } from './category-list'
import * as UI from '@/ui'

export type CategoryCardProps = {
  category: CategoryProps
}

export const CategoryCard = ({ category }: CategoryCardProps) => {
  return (
    <div className={`px-0 py-4  w-56 rounded-3xl bg-slate-100 ${hoverStyles}`}>
      <UI.Link type="basic" to={['learnCategory', { slug: category.slug }]}>
        <UI.Block gap="small">
          <UI.Heading size="xxs" color="lightBlue" className="text-center h-7">
            <h3>{category.title}</h3>
          </UI.Heading>

          <UI.Image
            src={`/images/learn/${category.image}`}
            alt={category.title}
            priority
            width={224}
            height={128}
            className="object-center object-cover w-56 h-32"
          />

          <div className="px-4 items-stretch flex flex-col justify-start">
            <UI.Paragraph color="midBlue">{category.description}</UI.Paragraph>
          </div>
        </UI.Block>
      </UI.Link>
    </div>
  )
}

export default CategoryCard
