import CategoryCard from './category-card'

export type CategoryProps = {
  title: string
  description: string
  image: string
  slug: string
}

export type CategoriesListProps = {
  categories: CategoryProps[]
}

export const CategoryList = ({ categories }: CategoriesListProps) => {
  return (
    <div className="flex flex-wrap gap-y-10 gap-x-5 justify-around mx-auto">
      {categories.map((category) => (
        <CategoryCard key={category.title} category={category} />
      ))}
    </div>
  )
}

export default CategoryList
