import { NextSeo } from 'next-seo'
import CategoriesMarkdownData from '../../components/learn/categories.json'
import CircleBlur from '../../public/images/circleBlur.svg?svgr'
import CategoryList, { CategoryProps } from '../../components/learn/category-list'
import BlogWhatsNew from '../../components/learn/blog-whats-new'
import { getAllBlogs, getBlogsBySlugArr } from '../../helpers/blogs'
import TrendingArticlesList from '../../components/learn/trending-articles-list'
import AllArticles from '../../components/learn/all-articles'
import { BlogProps } from '../../components/learn/blog-card'
import * as UI from '@/ui'

export const LearnHub = ({
  articles,
  categories,
  trendingArticles,
}: {
  articles: BlogProps[]
  trendingArticles: BlogProps[]
  categories: CategoryProps[]
}) => {
  return (
    <>
      <NextSeo
        title="Learn Hub"
        description="Become the expert in your own health with health and wellbeing articles and guides written by the doctors and scientists at Selph."
      />

      <UI.Block gap="large" className="relative mb-28 lg:mb-32">
        <CircleBlur className="absolute top-0 left-0 -translate-x-1/3 translate-y-1/4 w-[800px] h-[800px] md:w-[1500px] md:h-[1500px]" />

        <CircleBlur className="absolute top-1/2 right-0 translate-x-1/3 w-[800px] h-[800px] md:w-[1500px] md:h-[1500px]" />

        <UI.Block gap={{ default: 'large', md: 'xxl' }} className="relative max-w-7xl mx-auto">
          <UI.Block gap="xl">
            <UI.Heading
              color="midBlue"
              size={{ default: 'small', md: 'medium' }}
              weight="regular"
              className="text-center"
            >
              <h2>Health Topics</h2>
            </UI.Heading>

            <CategoryList categories={categories} />
          </UI.Block>

          <UI.Divider color="pink" />

          <UI.Block gap="xl" className="max-w-5xl mx-auto">
            <UI.Heading
              color="midBlue"
              size={{ default: 'small', md: 'medium' }}
              weight="regular"
              className="text-center"
            >
              <h2>What&apos;s New</h2>
            </UI.Heading>

            <BlogWhatsNew blog={articles[0]} />
          </UI.Block>

          <UI.Divider color="pink" />

          <UI.Block gap="xl">
            <UI.Heading
              color="midBlue"
              size={{ default: 'small', md: 'medium' }}
              weight="regular"
              className="text-center"
            >
              <h2>Trending Articles</h2>
            </UI.Heading>
            <TrendingArticlesList articles={trendingArticles} />
          </UI.Block>

          <UI.Divider color="pink" />

          <UI.Block gap="xl">
            <UI.Heading
              color="midBlue"
              size={{ default: 'small', md: 'medium' }}
              weight="regular"
              className="text-center"
            >
              <h2>All Articles</h2>
            </UI.Heading>

            <AllArticles articles={articles} />
          </UI.Block>
        </UI.Block>
      </UI.Block>
    </>
  )
}

LearnHub.layoutProps = {
  header: {
    title: 'Learn Hub',
    description: [
      "If you want to become an expert in your own health, you'll need answers to some important questions. You'll find those answers right here.",
      'Our medical experts review the evidence and dissect the science to separate fad from fiction and give you - in plain English - actionable insights you can trust.',
    ],
    // searchButton: {
    //   label: 'Search',
    //   placeholder: 'Search the Learn Hub',
    //   action: () => {
    //     alert('Searching articles')
    //   },
    // },
  },
}

export default LearnHub

export const getStaticProps = async () => {
  const articles = getAllBlogs()
  const categories = Object.values(CategoriesMarkdownData) as CategoryProps[]
  const trendingArticles = await getBlogsBySlugArr([
    '7-evidenced-based-actions-you-can-take-to-prevent-bowel-cancer-or-pick-it-up-earlier',
    'when-should-you-start-screening-for-bowel-cancer',
    'what-is-hba1c-and-whats-the-hba1c-normal-range',
  ])

  return {
    props: {
      articles,
      categories,
      trendingArticles,
    },
  }
}

export const hoverStyles =
  'overflow-clip shadow-[1.5px_1.5px_0_1.5px_#0D4780] transform transition-transform duration-200 ease-in-out hover:-translate-y-4 hover:shadow-[1.5px_1.5px_0_1.5px_#E22E89]'
